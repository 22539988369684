.property_wrapper_v2 {
    .container {
      max-width: 1200px;
    }
  
    .p_header {
      background: var(--primary);
      padding: 20px;
      margin-top: 30px;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
  
      .text-header-title {
        font-size: 2.7rem;
        font-weight: bolder;
        color: #fff;
        @media (max-width: 1100px) {
          font-size: 2.2rem;
        }
        @media (max-width: 890px) {
          font-size: 1.8rem;
        }
      }
  
      @media (max-width: 1100px) {
        .logo {
          max-width: 110px;
        }
      }
    }
  
    .s_header {
      margin-top: 10px;
  
      .h_header {
        margin-bottom: 15px;
  
        p {
          margin: 0;
          display: flex;
          align-items: center;
        }
      }
      .h_text {
        display: flex;
        background: #4a84b2;
        color: #fff;
        padding: 7px 10px;
        border-radius: 0;
        font-size: 14px;
        align-items: center;
      }
  
      .user_det {
        font-weight: 500;
        font-size: 16px;
        color: #000;
      }
  
      .s_btn {
        text-align: right;
  
        .btn {
          max-width: 180px;
          width: 100%;
          padding: 8px 10px;
          font-size: 15px;
        }
      }
  
      h2 {
        font-size: 30px;
        margin: 0;
      }
    }
    .s_banner {
      // margin-bottom: 25px;
  
      .sbanner_inr {
        min-height: 350px;
        height: 100%;
        position: relative;
        margin-top: 15px;
        border-radius: 10px;
        overflow: hidden;
  
        img {
          position: absolute;
          height: 100%;
          width: 100%;
          object-fit: cover;
          z-index: -1;
        }
  
        .cnt {
          position: relative;
          z-index: 1;
          text-align: center;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          color: #fff;
  
          h2 {
            font-weight: bold;
            font-size: 40px;
          }
          p {
            font-size: 25px;
            font-weight: 400;
          }
        }
  
        &:after {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: #00000096;
          z-index: -1;
        }
  
        .f_sec {
          display: flex;
          align-items: center;
          margin-bottom: 30px;
  
          .icon {
            width: 60px;
            height: 60px;
            border: 1px solid var(--primary);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
  
            img {
              width: 35px;
            }
          }
  
          .cont h3 {
            font-size: 18px;
            font-weight: normal;
            margin-bottom: 5px;
          }
          .cont h4 {
            margin: 0;
            color: var(--primary);
            font-size: 23px;
          }
        }
      }
    }
    .hyp-link {
      font-size: 0.9rem !important;
      font-weight: 600;
    }
  
    .custom-border-primary {
      border: 2px solid var(--primary);
      border-radius: 0.4rem;
      border-bottom: none;
      border-top: none;
    }
  
    .fund-link {
      position: absolute;
      bottom: -30px;
      left: 0;
      width: 100%;
      text-align: center;
    }
    .sup-cus {
      font-size: 0.7rem !important;
      font-weight: 600;
      left: 0.1rem;
      top: -0.8rem !important;
    }
    .sup-cus-low {
      font-size: 0.7rem !important;
      font-weight: 600;
      left: 0.1rem;
      top: -0.6rem !important;
    }
    .s_feature {
      margin-bottom: 15px;
  
      .f_sec {
        background: #fff;
        padding: 10px;
        display: flex;
        margin-bottom: 20px;
        align-items: flex-end;
        border: 1px solid #a3c3db;
        background: #edf7ff;
        border-radius: 10px;
        box-shadow: 0 0 15px 1px #dbdbdb;
        position: relative;
  
        .icon {
          width: 70px;
          height: 70px;
          border: 1px solid #a3c3db;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
  
          img {
            width: 40px;
          }
        }
  
        .cont h3 {
          font-size: 20px;
          font-weight: normal;
        }
        .cont h4 {
          font-size: 20px;
          color: var(--primary);
        }
      }
    }
  
    .s_more {
      margin-bottom: 1rem;
  
      .mr_over {
        background: #fff;
        padding: 20px 15px;
        border-radius: 10px;
      }
  
      h4 {
        margin-bottom: 15px;
      }
  
      .h_section {
        height: 100%;
  
        .divide-section {
          background: #fff;
          padding: 20px 15px;
          border-radius: 10px;
          margin-bottom: 1.1rem;
  
          p {
            margin-bottom: 0.5rem;
          }
        }
      }
      // .mr_det {
      //   // margin-bottom: 50px;
      // }
  
      .docs a {
        display: flex;
        align-items: center;
        margin-bottom: 11px;
  
        svg {
          color: var(--primary);
          margin-right: 5px;
          width: 15px;
        }
      }
    }
  }
  
  .notes-addon {
    background: #fff;
    padding: 20px 15px;
    border-radius: 10px;
  }
  
  .offering-desc {
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .offering-desc-sub {
    font-size: 0.77rem;
    font-style: italic;
    color: var(--primary);
  }
  
  .modal-header {
    background: var(--primary);
    color: #fff;
  }
  .modal-header .btn-close {
    color: #fff;
    background-color: #fff;
    opacity: 1;
    margin-right: 10px !important;
  }
  .sp_wrapper {
    .single_sp {
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 30px;
      border: 1px solid #eee;
      background: #f6f6f6;
  
      .sp_img {
        height: 100%;
        display: flex;
        align-items: center;
        background: #fff;
        padding: 5px;
        border-radius: 10px;
  
        img {
          border-radius: 10px;
          object-fit: cover;
        }
      }
  
      .sp_cnt {
        h3 {
          margin: 0 0 5px 0;
          font-size: 20px;
        }
  
        h4 {
          font-size: 17px;
          color: #444;
          margin-bottom: 10px !important;
          font-weight: normal;
        }
        p {
          margin-bottom: 0;
          font-size: 14px;
        }
      }
    }
  }
  
  .head_btn_sm {
    display: none;
  }
  .head_btn_sm .btn-light {
    // background: #0c3c60;
    // color: #fff;
    border-color: #0c3c60;
  }
  .head_btn_sm .btn {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 12px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .head_btn_sm .me-3 {
    margin-right: 5px !important;
  }
  .head_btn_sm .btn-light:hover {
    background: #000;
    color: #fff;
    border-color: #000;
  }
  
  @media screen and (min-width: 1200px) {
    .property_wrapper_v2 .custom-border-primary {
      overflow: hidden;
    }
  }
  
  @media screen and (max-width: 767px) {
    .property_wrapper_v2 .logo img {
      max-width: 110px;
    }
    .property_wrapper_v2 .p_header {
      margin-top: 15px;
      margin-top: 15px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 15px 10px 15px;
    }
    .property_wrapper_v2 .s_header {
      margin-top: 10px;
      text-align: center;
    }
    .property_wrapper_v2 .p_header .text-header-title {
      font-size: 1rem;
      margin-top: 15px;
    }
    .custom-height-fund-img {
      height: auto !important;
    }
  
    .property_wrapper .s_more .docs a svg {
      font-size: 13px;
      width: 16px;
    }
    .p_header .btn {
      font-size: 11px;
      margin-right: 5px !important;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 6px;
      padding-bottom: 6px;
    }
    .property_wrapper_v2 .s_header h2 {
      font-size: 26px;
    }
    .property_wrapper_v2 .s_header .s_btn {
      text-align: center;
      margin-top: 15px;
    }
    .property_wrapper_v2 .s_header .s_btn .btn {
      max-width: initial;
      padding: 7px 15px;
      font-size: 14px;
      margin-top: 1px;
      display: inline-block;
      width: auto;
      margin-bottom: 5px;
    }
    .property_wrapper_v2 .s_feature .f_sec {
      margin-bottom: 15px;
    }
    .table th,
    .table td {
      font-size: 13px;
      line-height: 1.3;
    }
    .property_wrapper .s_banner .sbanner_inr {
      min-height: 270px;
    }
    .mr_over {
      margin-bottom: 30px;
    }
    .wrap-custom {
      flex-wrap: wrap;
      justify-content: center;
    }
  
    .wrap-custom .btn {
      width: auto !important;
      font-size: 14px;
      padding: 7px 15px;
    }
  
    .head_btn_sm {
      display: block;
      margin-top: 20px;
    }
    .head_btn {
      display: none;
    }
  }
  
  .carousel-item {
    img {
      border-radius: 5px;
    }
  }
  
  .txt-shadow {
    text-shadow: 2px 2px #222;
  }
  
  .carousel-caption {
    background: #45454594;
  }
  
  .custom-invest-btn {
    background: #4993ff !important;
    border-color: #4993ff !important;
    color: #fff !important;
  
    &:hover {
      background: #000 !important;
      font-size: bold !important;
      color: #fff !important;
    }
  }
  
  .theme-bg-table {
    th {
      background-color: #0c3c60 !important;
      color: #ffffff;
      font-weight: 500;
    }
  }
  
  .card-like {
    padding: 15px 25px;
    box-shadow: inset 0 0 5px 0px #ccc;
    background-color: rgba(236, 236, 236, 0.662);
    border-radius: 5px;
    margin-bottom: 1rem;
    text-align: center;
  
    label {
      font-size: 11px;
      font-weight: 600;
    }
  
    .value {
      font-size: 15px;
      font-weight: 600;
      color: #0c3c60;
    }
  }
  
  .custom-bg-footer {
    th {
      background-color: #d2d2d2 !important;
      color: #222;
      font-weight: 500;
    }
  }
  
  .wrap-custom {
    flex-wrap: wrap;
    @media (min-width: 1024px) {
      flex-wrap: nowrap;
    }
  }
  
  .alink {
    &:hover {
      color: #0d6efd;
    }
  }
  
  .svg-icon-height {
    svg {
      width: 1.8rem !important;
      height: 1.8rem !important;
    }
  }
  
  .phase {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 1rem;
    border-radius: 5px;
    margin-bottom: 1.5rem !important;
    table {
      margin-bottom: 0 !important;
    }
  }
  
  .phase-row {
    border-top: 20px solid #eee;
    td {
      // padding: 3px 0.5rem !important;
      background: linear-gradient(to top, #edf7ff, #97b2c7) !important;
      color: var(--primary);
      h6 {
        font-weight: 700;
        margin-bottom: 0;
      }
      small {
        font-weight: 500;
        color: rgb(65, 64, 64) !important;
      }
    }
  }
  
  table {
    border-radius: 0.3rem;
  }
  .table > :not(caption) > * > * {
    border-bottom-width: 0px !important;
  }
  
  .help-text-modal {
    font-size: 11px;
  }
  
  .cover-banner {
    height: 350px;
    @media (min-width: 1024px) {
      height: 500px;
    }
  }
  