.header_wrapper{
    background: var(--primary);
    padding: 0.7rem 1.5rem;

    .head_menu {
        display: flex;

        a {
            border: 1px solid #fff;
            padding: 0.4rem 2rem;
            border-radius: 25px;
            color: #fff;
            &:last-child {
                margin-left: 1rem;
            }

            &:hover {
                background: #000;
                color: #fff;
            }
        }
    }

    .main_menu{
        display: flex;
        align-items: center;
        justify-content: center;

        .nav-link {
            color: #444;
            font-weight: bold;
            margin-right: 1rem;

            &:last-child{
                margin: 0px;
            }
        }

        .nav-item.dropdown {
            margin-left: 1rem;
        }
    }

    .notification{
        margin-right: 1rem;

        .notification_btn{
            padding: 0px;
            background: none !important;
            outline: none !important;
            box-shadow: none !important;
            border: none;
            &:active, &:focus, &:hover{
                background: none !important;
                outline: none !important;
            }
            &:after{
                display: none;
            }
            .icon-button {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                height: 45px;
                border: none;
                outline: none;
                border-radius: 50%;
                .not_icon {
                    font-size: 2rem;
                    color: #fff;
                }
            }
              
            .icon-button:hover {
                cursor: pointer;
            }
              
            .icon-button__badge {
                position: absolute;
                top: -1px;
                right: -6px;
                width: 20px;
                height: 20px;
                background: red;
                color: #ffffff;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                font-size: 0.7rem;
            }
        }
        
        .dropdown-menu {
            width: 100%;
            max-width: 25rem;
            min-width: 25rem;
            padding: 0.6rem 0.8rem 0.8rem 0.8rem;
            border: 1px solid #eee;
            .notification_list{
                h4{
                    font-size: 0.9rem;
                    color: #444;
                    margin-bottom: 1rem;
                    border-bottom: 1px solid #eee;
                    padding-top: 0.5rem;
                    padding-bottom: 0.4rem;
                }
                .list_not {
                    display: flex;
                    align-items: center;
                    margin-bottom: 0.8rem;
                    background: #f5f5f5;
                    padding: 5px 10px;
                    border-radius: 6px;
                    border: 1px solid #eee;
                    &:hover{
                        background: #fff;
                    }
                    .icon img {
                        width: 3rem;
                        border-radius: 50%;
                        margin-right: 0.6rem;
                        border: 1px solid #ddd;
                    }
                    .content {
                        width: calc(100% - 3rem);
                        .title {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-size: 0.8rem;
                            color: #444;
                            margin-bottom: 0.2rem;
                            .time {
                                font-size: 0.6rem;
                                font-weight: normal;
                            }
                        }
                        .details {
                            font-size: 0.8rem;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
    
    .acc_logo{
        max-width: 60px;
    }
    .act_head {
        align-items: center;
        display: flex;
        justify-content: end;
        border: none;
        border-radius: 10px;
        padding: 0.2rem 0.9rem 0.2rem;
        float: right;
        background: #fff;
        border: 1px solid #ddd !important;
        &:active, &:focus, &:hover{
            background: #fff !important;
        }
        .act_img img {
            border-radius: 50%;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            object-fit: cover;
        }
        .profile_meta {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-left: 0.8rem;
            font-weight: 500;
            font-size: 0.8rem;
            float: left;
            color: #444;
            .act_cost {
                color: var(--primary);
            }
        }
        
    }
    .act_head::after{
        color: #888 !important;
    }
    .acc_dropdown .dropdown-menu{
        font-size: 0.9rem;
        color: #666;
        padding-top: 1rem;
        border: 1px solid #eee;
        .dropdown-item{
            margin-bottom: 0.3rem;
            svg{
                margin-right: 0.4rem;
                color: #666;
            }
            &:hover{
                background: none;
                color: #333333;
            }
        }
        
    }
}
