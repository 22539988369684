.otp-input {
    text-align: center;
  
    label {
      margin-bottom: 0.5rem;
    }
  }
  
  .otp-input > div {
    justify-content: center;
  }
  
  .otp-input input {
    width: 2.5rem !important;
    height: 2.5rem;
    padding-top: 5px;
    font-size: 1rem;
    margin: 0 0.4rem;
    border: 1px solid #ced4da;
    border-radius: 0.5rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-size: 1.1rem !important;
  
    &:focus {
      color: #212529;
      background-color: #fff;
      border-color: rgba(255, 193, 7, 0.4);
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgb(246 151 10 / 9%);
    }
  }
  