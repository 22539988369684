.login_wrapper {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-image: url(../../../images/bg_6.jpg);
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 0;
    min-height: 100vh;
  
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: #ffffff47;
      z-index: -1;
    }
  
    .l_inner {
      background: #fff;
      padding: 3rem 4rem;
      border-radius: 10px;
  
      h2 {
        text-align: center;
        color: var(--primary);
        font-weight: 600;
        font-size: 1.2rem;
      }
  
      .sub-title {
        text-align: center;
        color: var(--primary);
        margin-bottom: 1.5rem;
      }
  
      p.text-danger {
        font-size: 12px;
      }
  
      label {
        margin-bottom: 7px;
      }
  
      .form-control {
        height: 45px;
        font-size: 0.9rem;
      }
  
      .btn {
        width: 100%;
        height: 45px;
        margin-top: 10px;
      }
  
      .password {
        position: relative;
  
        button {
          border: none;
          padding: 0;
          outline: none;
          background: none;
          color: #888;
          position: absolute;
          top: 44px;
          right: 10px;
        }
      }
  
      .extra {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.5rem;
        font-size: 0.9rem;
  
        label {
          margin: 0 !important;
        }
  
        a {
          color: #444;
        }
      }
  
      .term_det label {
        font-size: 0.9rem;
      }
    }
  }
  
  .password-group {
    position: relative;
  
    .eye-icon {
      position: absolute;
      right: 2rem;
      top: 0.5rem;
    }
  }