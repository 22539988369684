 
.invest_wrapper{

    padding-top: 3rem;
    padding-bottom: 3rem;
    .pr_short{

        background: #fff;
        padding: 15px;
        border-radius: 10px;

        ul{
            padding: 0px;
            border-radius: 6px;
            overflow: hidden;
            margin-bottom: 10px;

            li{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 5px;
                font-size: 0.9rem;
                padding-bottom: 5px;

                &:last-child {
                    border: none;
                }

            }
        }

        img {
            border-radius: 6px;
        }
        h4 {
            color: var(--primary);
            font-size: 1.3rem;
            font-weight: 600;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    }

    .inv_det {
        background: #fff;
        padding: 15px;
        border-radius: 10px;

        .react-tabs__tab-list {
            border-radius: 65px;
            border: none;
            width: 100%;
            margin-top: 1rem;
            margin-bottom: 1rem;
            display: flex;
            justify-content: center;
        }
        
        .react-tabs__tab {
            margin: 0;
            max-width: 160px;
            width: 100%;
            border: none;
            border-radius: 25px;
            margin-bottom: 10px;
            padding-left: 15px;
            padding-right: 13px;
            text-align: center;
            color: #888;
            position: relative;
            z-index: 1;
            font-size: 1.1rem;

            &:after {
                content: '';
                width: 100%;
                height: 7px;
                background: #eee;
                position: absolute;
                left: 0;
                top: 28px;
                z-index: -1;
            }
            
            &:first-child:after {
                border-radius: 10px 0px 0px 10px;
            }

            &:last-child:after {
                border-radius: 0px 10px 10px 0px;
            }
        }
        
        .react-tabs__tab--selected {
            color: var(--primary);

            span {
                background: var(--primary) !important;
                color: #fff;
            }

            &:after{
                background: var(--primary) !important;
            }

        }
        
        .react-tabs__tab span {
            border: 1px solid #ddd;
            display: block;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            line-height: 50px;
            font-size: 1.5rem;
            margin: 0 auto 5px;
            background: #fff;
        }
        
        .react-tabs__tab:last-child {
            margin-right: 0;
        }

        .tab_cnt {
            margin-top: 3rem;
        }
        
        .offer_box {

            margin: 0 auto;
            max-width: 430px;
            text-align: center;
            border: 1px solid #eee;
            padding: 30px 30px;
            box-shadow: 0 0 10px #ddd;
            border-radius: 10px;
            margin-bottom: 2rem;

            label {
                font-size: 1.1rem;
                font-weight: 500;
                margin-bottom: 1rem;
            }
            .price_bx {
                position: relative;
                font-size: 2rem;

                input {
                    height: 50px;
                    margin-bottom: 1.5rem;
                    font-size: 1.5rem;
                    padding-left: 50px;
                }
                span {
                    position: absolute;
                    top: 1px;
                    left: 20px;
                    color: #888;
                }
            }
            
            ul {
                padding: 0;
                margin-top: 1rem;

                li {
                    list-style: none;
                    display: flex;
                    align-items: center;
                    font-size: 0.8rem;
                    margin-bottom: 3px;
                    
                    svg {
                        margin-right: 6px;
                        color: var(--primary);
                    }
                }
            }
        }
        
    }

    .acpt_det{
     
        label {
            font-size: 0.9rem;
        }
    }


    .nav_wrapper {
        border-top: 1px solid #deee;
        padding-top: 20px;
        border-bottom: 1px solid #deee;
        padding-bottom: 20px;

        .nav_btn {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        
    }
    

}