.sp_sec {
    background: #fff;
    padding: 20px 15px;
    border-radius: 10px;
    margin-top: 1rem;
  
    p {
      font-size: .9rem;
    }
  
    .single_sp {
      border-bottom: 1px solid #eee;
      padding-bottom: 20px;
      margin-bottom: 20px;
  
      &:last-child {
        border: none;
        padding-bottom: 0px;
      }
      .sp_cnt h3 {
        font-size: 17px;
        font-weight: 600;
        margin-bottom: 0.5rem;
        span {
          display: block;
          font-size: 14px;
          margin-top: 6px;
          &:first-child {
            font-weight: 300 !important;
          }
          &:last-child {
            font-weight: 600 !important;
          }
        }
      }
      .sp_cnt p {
        font-size: 0.9rem;
      }
      .sp_img {
        text-align: center;
        background: #eee;
        height: 100%;
        padding: 5px;
        border-radius: 10px;
  
        img {
          max-width: 210px;
          border-radius: 50%;
          border: 5px solid #fff;
          background: #fff;
          width: 100%;
        }
      }
    }
  }
  
  .cover_image {
    img {
      border-radius: 10px;
    }
  }
  .text-justify {
    text-align: justify;
  }
  
  .custom-css-tabs {
    li {
      margin-bottom: 0 !important;
    }
  
    .nav-link {
      font-size: 16px;
      font-weight: 600;
      color: #0c3c60 !important;
    }
  }
  
  .custom-height-fund-img {
    height: 370px;
    object-fit: cover;
  }
  
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    background-color: #0c3c60 !important;
    color: #fff !important;
  }
  
  .custom_tab3 {
    text-align: center;
    display: block !important;
    background: #eee;
    max-width: 850px;
    margin: 30px auto 30px !important;
    padding: 10px 10px 1px 10px !important;
    border-radius: 10px;
    border: none !important;
  
    .nav-item {
      display: inline-block;
      margin: 0 !important;
  
      button {
        width: auto !important;
        background: #eee !important;
        color: #000;
        border: none !important;
        border-radius: 10px;
        margin-right: 10px !important;
        margin-bottom: 9px;
  
        &:active,
        &:hover {
          background: #fff !important;
          color: #0c3c60 !important;
        }
  
        @media screen and (min-width: 768px) {
        }
      }
    }
  
    .nav-link.active {
      background: #fff !important;
      color: #0c3c60 !important;
    }
  }
  .carousel-item img {
    min-height: 375px;
    width: 100%;
    object-fit: cover;
    margin-bottom: 30px;
  }
  .property_detail {
    ul {
      padding: 0;
  
      li {
        list-style: none;
  
        margin-bottom: 8px;
  
        b {
          margin-right: 7px;
          font-weight: 600;
        }
        svg {
          color: var(--primary);
          margin-right: 6px;
        }
      }
    }
  }
  
  @media screen and (max-width: 768px) {
    .custom_tab3 .nav-item button {
      margin-bottom: 5px;
      font-size: 14px !important;
      padding: 5px 10px !important;
    }
    .carousel-item img {
      min-height: 300px;
    }
  }
  
  .help-text-track {
    font-size: 1rem;
  
    ul {
      li {
        font-size: 0.7rem;
        margin-bottom: 0.3rem;
        font-style: italic;
      }
    }
  }
  
  .app-color {
    color: var(--primary);
  }
  
  @media (min-width: 768px) {
    .custom-width-table {
      width: 68% !important;
    }
  }
  
  .table-track-record {
    th,
    td {
      white-space: nowrap !important;
      font-size: 0.75rem;
      vertical-align: middle;
    }
  
    .text-start {
      white-space: nowrap !important;
    }
  }
  