.disclaimer_sec {
  background: #fff;
  padding: 20px 15px;
  border-radius: 10px;

  p {
    font-size: 13px;
    margin-bottom: 0.75rem;
  }
}
