.call_to_action {
  background: var(--primary);
  padding: 20px 15px 20px 40px;
  border-radius: 10px;
  color: #fff;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  .row {
    align-items: center;
  }
  .in-btn {
    font-size: 14px;

    float: right;
    padding-left: 10px;
    padding-right: 10px;
    a {
      color: var(--primary);
      background: #fff;
      padding: 0.6rem 1rem;
      border-radius: 10px;
      font-weight: bold;
      font-size: 15px;
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
      }
    }
  }
  h2 {
    margin: 0;
    line-height: 1.5;
    font-size: 1.2rem;

    a {
      color: #fff;
    }
  }
}

@media (max-width: 768px) {
  .call_to_action {
    text-align: center;
    padding: 30px 15px 25px 15px;
  }
  .call_to_action .in-btn {
    margin-top: 0px;
    margin: 0 0 8px 0;
    float: none;
    padding: 3px;
  }
  .call_to_action h2 {
    margin: 0 0 10px 0;
    line-height: 1.3;
    font-size: 22px;
  }
  .call_to_action .in-btn a {
    font-size: 13px;
  }
}
