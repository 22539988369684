.inv_wrapper {
    margin-top: 3rem;
    margin-bottom: 4rem;
    
    .s_detailed {
        background: #fff;
        padding: 30px 15px;
        border-radius: 10px;

        h1 {
            font-size: 1.6rem;
            color: #444;
            line-height: 20px;
            border-bottom: 1px solid #eee;
            margin-bottom: 10px;
            padding-bottom: 15px;
        }

        h3 {
            font-size: 1.2rem;
            color: #444;
        }

        .box_1 {
             margin-bottom: 30px;
            padding: 30px 20px 0px 20px;
            border-radius: 10px;
 
            h3 {
                font-size: 1.2rem;
                margin-bottom: 15px;
            }

            .form-group label {
                margin-bottom: 5px;
                font-size: 14px;
            }
        }
    }
}









  