.listing_wrappper {
  padding: 3rem 3rem;
  background: #f5f5f5;
}

.banner_wrapper {
  padding: 6rem 3rem;
  background-color: #fff;
  background-image: url(../../images/banner.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 3rem;
  .banner_inner {
    h2 {
      font-weight: 700;
      font-size: 3rem;
      color: var(--primary);
      margin-bottom: 1.5rem;
    }

    .desc {
      font-size: 1.2rem;
    }

    .button_p {
      .btn {
        font-size: 1.2rem;
        padding: 0.6rem 2.5rem;
      }
    }
  }
}

.featured_box {
  cursor: pointer;
  padding: 0px;
  position: relative;
  transition: 0.5s;
  border-radius: 6px;
  border: 1px solid #eee;
  overflow: hidden;
  background: #ffff;
  .h_action {
    position: absolute;
    top: 10px;
    left: 8px;
    z-index: 1;
    span {
      background: #ff0000;
      color: #fff;
      padding: 5px 8px 5px 8px;
      font-size: 0.8rem;
      font-weight: bold;
      border-radius: 5px;
    }
    .l_sale {
      margin-right: 10px;
    }
    span.p_type {
      background: #49af0d;
    }
  }
  .p_image {
    position: relative;
    overflow: hidden;
    .p_action {
      position: absolute;
      bottom: 8px;
      right: 8px;
      .btn {
        width: 35px;
        padding: 0px !important;
        height: 35px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          margin: 0 !important;
        }
      }
    }
    img {
      width: 100%;
      object-fit: cover;
      transition: 0.5s;
    }
  }
  .featured_details {
    padding: 0 0.8rem;
    margin-bottom: 0.6rem;
  }
  .f_head {
    margin-bottom: 0.68rem;
    margin-top: 0.68rem;
  }
  ul {
    padding: 0px;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 20px;
  }
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 0.9rem;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    &:last-child {
      border: none;
    }
  }

  p {
    margin-top: 10px;
    color: #666;
    font-size: 0.9rem;
  }
  .m_btn .btn {
    border-radius: 25px;
    max-width: 230px;
    width: 100%;
  }

  &:hover {
    box-shadow: 0 0 15px #0000002b;

    .m_btn .btn {
      background: #000 !important;
    }

    img {
      transform: scale(1.07);
    }
  }
}

.search_wrapper {
  padding: 3rem 3rem 0;
  background: #f5f5f5;

  .search_inner {
    background: #fff;
    padding: 1.5rem 1rem;
    border-radius: 10px;

    .search_header {
      display: flex;
      align-items: center;

      .search_w {
        display: flex;
        width: 100%;
        position: relative;

        input {
          height: 50px;
          width: 100%;
          border: 1px solid #ddd !important;
        }
        svg {
          position: absolute;
          right: 0;
          transform: translate(-75%, 79%);
          font-size: 20px;
          color: #888;
        }
      }
    }

    .search_btn {
      background: none !important;
      border: 1px solid #ddd !important;
      color: #666 !important;
      max-width: 190px;
      width: 100%;
      background: #fff;
      padding: 12px 20px;
      height: 50px;
      margin-right: 1rem;

      svg {
        color: var(--primary);
        margin-left: 0.3rem;
      }
    }

    h4 {
      font-size: 17px;
    }
    .cnt_view .row {
      border: 1px solid #eee;
      margin: 0;
      margin-top: 1rem;
      padding: 1.5rem 1rem;
    }
  }
}
