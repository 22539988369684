.footer_wrapper{
    background: #eee;
    border-top: 1px solid #d5d5d5;
    padding: 5rem 3rem 1rem;

    h3 {
        font-size: 1.3rem;
        color: var(--primary);
        font-weight: 600;
        margin-bottom: 15px;
    }

    ul {
        padding-left: 15px;

        li {
            margin-bottom: 10px;
    
            a {
                color: #212529;
            }
        }


    }
    
    .footer_bootom {
        border-top: 1px solid #ccc;
        padding-top: 2rem;
        margin-top: 4rem;
    }

    .footerlinks {
        text-align: right;

        a {
            color: #444;
            text-decoration: none;
        }
        
    }

}