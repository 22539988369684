.s_header{
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 2rem;
   

    .s_header_inr{
        background: #fff;
        padding-top: 1rem;
        padding-bottom: 0.4rem;
        border-radius: 10px;
    }

    h1{
        font-size: 2rem;
        font-weight: 600;
    }

    .button_p {
        text-align: center;
    }
}

.property_de1_inr{
    background: #fff;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 10px;
    
    p.button_p {
        text-align: right;
    }
    
    .main_table table {
        background: var(--primary);
        color: #fff;
    }
    
    .main_table td {
        padding: 10px;
    }
    .gallery img {
        width: 100%;
    }

    .head_nav {
        margin-top: 2rem;

        ul {
            padding: 0;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: space-between;

            li {
                list-style: none;
                display: inline-block;
                margin-right: 10px;
                margin-bottom: 10px;

                a {
                    padding: 7px 17px;
                    border: 1px solid #ddd;
                    border-radius: 6px;
                    font-size: 0.9rem;
                    color: #000;
                    transition: 0.5s;

                    &.active, &:hover {
                        background: var(--primary);
                        color: #fff;
                    }
                }

                &:last-child{
                    margin-right: 0px;
                }
            }
        }
    }

    h3 {
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 1rem;
    }
    ul {
        margin-bottom: 2.5rem;
    }

    li ul{
        margin-top: 1rem;
    }
}